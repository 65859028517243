import axios from "axios";

const getFormStatus = (item) => {
    if (item.isUnpub) {
        return 'Unpublished';
    } else if (!item.hasPub) {
        return 'Draft';
    } else if (item.draft?.data) {
        return 'Draft';
    } else {
        return 'Published';
    }
};
/*const blobToBase64 = (file) => {
    console.log('[blobToBase64] file', file);
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (e) => {
        console.log('[blobToBase64] e', e);
        console.log('[blobToBase64] reader.result', reader.result);
        return reader.result;
    };
};*/
// 获取图片file转base64
const blobToBase64 = (blob) => {
    return new Promise((resolve, _) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result);
        reader.readAsDataURL(blob);
    });
};


// Base64 转为 Blob
const base64ToBlob = (fileDataURL) => {
    //console.log('[base64ToBlob] fileDataURL', fileDataURL);
    let arr = fileDataURL.split(","),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);
    //console.log('[base64ToBlob] mime', mime);
    while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
    }
    return new Blob([u8arr], { type: mime });
}

const fileAndBlobToCanvas = (fileDataURL, canvas) => {
    let img = new Image();
    img.src = fileDataURL;
    /*let canvas = document.createElement('canvas')
    if (!canvas.getContext) {
      alert('浏览器不支持canvas')
      return
    }*/
    let ctx = canvas.getContext('2d');
    //document.getElementById('container').appendChild(canvas);
    img.onload = () => {
        ctx.drawImage(img, 0, 0, img.width, img.height)
    }
}

const formatViewData = (data) => {

    //console.log('[formatViewData] data', data);

    const keys = Object.keys(data);
    if (keys.length > 0) {
        for (let i = 0; i < keys.length; i++) {
            const key = keys[i];
            //console.log('[formatViewData] key', key);
            if (key.indexOf('signature') === 0) {

                //console.log('[formatViewData] val', data[key]);

                const blob = base64ToBlob(data[key]);

                //console.log('[formatViewData] ', key, blob);

                data[key] = blob;
            }
        }
    }

    return data;
};
/* 
const formatFormData = (data, files) => {


    return new Promise((resolve, _) => {


        if (files && files.length > 0) {
            for (let i = 0; i < files.length; i++) {
                let file = files[i];
                data[file.key] = file.url;
            }
        }

        console.log('[formatFormData] data', data);

        let signatureKey = '';
        const keys = Object.keys(data);
        for (let i = 0; i < keys.length; i++) {
            let key = keys[i];
            if (key.indexOf('signature') === 0) {
                signatureKey = key;
                break;
            }
        }
        if (signatureKey && signatureKey.length > 0) {
            blobToBase64(data[signatureKey]).then((base64) => {

                console.log('[formatFormData] ', signatureKey, base64);
                data[signatureKey] = base64;

                resolve(data);
            });
        } else {

            resolve(data);
        }
    });

}; */

const formatFormData = (data, files, formSchema) => {


    if (files && files.length > 0) {
        let init = [];
        for (let i = 0; i < files.length; i++) {
            let file = files[i];
            if (file.part) {
                if (init.indexOf(file.key) < 0) {
                    init.push(file.key);
                    data[file.key] = [];
                }
                data[file.key].push(file.url);
            } else {
                data[file.key] = file.url;
            }
        }
    }

    let keys = Object.keys(data);

    let price = 0;

    for (let i = 0; i < keys.length; i++) {
        let key = keys[i];
        let schemaType = formSchema[key]?.type || '';
        if (key.indexOf('captcha') === 0 || schemaType === 'captcha') {
            delete data[key];
        } else if (key.indexOf('radiogroup') === 0 || schemaType === 'radiogroup') {
            price = parseInt(data[key]) || price;
            //console.log('[formatFormData] price', price);
        }
    }

    if (price > 0) {
        data = { ...data, price: price };
    }

    //console.log('[formatFormData] data', data);
    return data;

};

const getUploadFiles = (data, formId, formSchema) => {

    let files = [];

    const keys = Object.keys(data);
    // console.log('[getUploadFiles] data', data);
    // console.log('[getUploadFiles] formSchema', formSchema);
    for (let i = 0; i < keys.length; i++) {
        let key = keys[i];
        let schemaType = formSchema[key]?.type || '';
        //console.log('[getUploadFiles] key', key, schemaType);
        if (key.indexOf('image') === 0 || key.indexOf('file') === 0 || key.indexOf('signature') === 0 || schemaType === 'image' || schemaType === 'file' || schemaType === 'signature') {
            if (data[key]) {
                files.push({
                    key: key,
                    formId: formId,
                    url: '',
                    data: data[key]
                });

                //console.log('[getUploadFiles] key', key, data[key]);
            }
        } else if (key.indexOf('multifile') === 0 || key.indexOf("multiImage") === 0 || schemaType === 'multifile' || schemaType === 'multiImage') {
            //console.log('[getUploadFiles] key', key, data[key]);
            let items = data[key];
            if (items && items.length > 0) {
                for (let i = 0; i < items.length; i++) {

                    files.push({
                        key: key,
                        formId: formId,
                        part: true,
                        url: '',
                        data: items[i]
                    });
                }
            }
        }
    }

    return files;
};



const uploadFiles = (files) => {
    //console.log('[uploadFiles] files', files);
    const promises = files.map((file, index) => {
        const formData = new FormData();
        formData.append('file', file.data);
        formData.append('formId', file.formId);

        return axios.post('/api/upload-temp', formData)
            //.then(response => response.json())
            .then(data => {
                //console.log('File uploaded: data', data);
                //console.log('File uploaded: data.data', data.data);
                files[index].url = data.data;
            })
            .catch(error => console.error('Error uploading file:', error));
    });

    return Promise.all(promises); // 返回一个Promise，所有文件上传完毕后解决
};


const getFilesData = (schema, data) => {
    let files = [];
    if (schema) {
        const keys = Object.keys(schema);
        if (keys && keys.length > 0) {
            for (let i = 0; i < keys.length; i++) {
                let key = keys[i];
                //console.log('[getFilesData]', key, schema[key], data[key]);
                if (data[key]) {
                    let item = {
                        title: schema[key].label,
                        files: []
                    };

                    if (Array.isArray(data[key])) {
                        let items = data[key];
                        for (let j = 0; j < items.length; j++) {
                            let url = items[j];
                            let array = url.split('/');
                            let file = {
                                name: array[array.length - 1],
                                url: url
                            };
                            item.files.push(file);
                            files.push(item);
                        }
                    } else {
                        let url = data[key];
                        let array = url.split('/');
                        let file = {
                            name: array[array.length - 1],
                            url: url
                        };
                        item.files.push(file);
                        files.push(item);

                    }
                }
            }
        }
    }
    //console.log('[getFilesData] files', files);
    return files;
};

export { getFormStatus, blobToBase64, base64ToBlob, fileAndBlobToCanvas, formatViewData, formatFormData, uploadFiles, getUploadFiles, getFilesData };