<template>
  <div class="h-screen">
    <div ref="formContainer" :class="mainClass">
      <div v-if="siteInfo.ErrorMsg.length > 0" id="ci_error_msg" class="hwifMk">
        <div class="msg">{{ siteInfo.ErrorMsg }}</div>
        <div class="btn" @click="deleteError">X</div>
      </div>
      <main v-if="formInfo.Status === 'Unpublished'">
        <div class="submit-result" style="display: none;">
          <div>The form has been unpublished.</div>
        </div>
      </main>
      <main v-else-if="state.status === 0">
        <Vueform ref="form" class="themed-form" :config="config" :schema="formSchema" :endpoint="getEndpoint" />
      </main>
      <main v-else-if="state.status === 1">
        <div class="submit-result">
          <div>Your form has been successfully submitted.</div>
          <div>Thank you.</div>
          <div>
            <button @click="resetForm" :class="btnClass" :style="btnStyle">Close</button>
          </div>
        </div>
      </main>
      <main v-else-if="state.status === 2">
        <div class="submit-result">
          <div>Your submission failed.</div>
          <div>Please try again.</div>
          <div>
            <button @click="resetForm" :class="btnClass" :style="btnStyle">Close</button>
          </div>
        </div>
      </main>
    </div>
  </div>
</template>
<script>
import axios from 'axios';
import { emptyData } from '../data';
import { apiConfig } from '../config/api';
import { getFormStatus, formatFormData, uploadFiles, getUploadFiles } from '../utilities/dataHelper';


export default {
  name: 'Form',
  data() {
    return {
      mainClass: 'ci-form-main-iframe',
      btnClass: 'btn-close inline-block transition form-border-width-btn form-shadow-btn focus:outline-zero form-bg-btn form-color-btn form-border-color-btn form-p-btn form-radius-btn form-text cursor-pointer transition-transform ease-linear focus:form-ring transform hover:scale-105',
      btnStyle: '',
      formSchema: emptyData.schema,
      siteInfo: {
        ErrorMsg: '',
        EnableSave: false
      },
      formInfo: {
        Title: 'My Form',
        //Id: parseInt(this.$route.params['formId'] || 0),
        Uid: this.$route.params['formId'],
        Id: 0,
        SiteId: 5,
        FolderId: 0,
        Data: '',
        Status: "Published",
        HasPub: false,
        HasDraft: false
      },
      state: {
        resizeObserve: null,
        // status: 0: init, 1: success, 2: failed
        status: 0,
        submit: 0,
        height: 0
      },
      parentInfo: {
        init: false,
        itmId: 0,
        pageUrl: '',
        siteId: 0,
        theme: null,
        title: '',
        isMobile: false
      },
      theme: {
        primary: 'rgb(0, 120, 212)',
        darker: 'rgb(0, 120, 212)'
      }
    }
  },
  methods: {
    deleteError() {
      this.siteInfo.ErrorMsg = '';
    },
    getFormSchema() {
      axios({
        method: 'get',
        url: `${apiConfig.GetApiUrl}/${this.formInfo.Uid}`,
      }).then((res) => {

        let result = res.data;

        const status = getFormStatus(result);
        const title = result.title;
        let _fromData = (status === 'Unpublished') ? '' : (result.data || "");
        if (_fromData.length < 100) {
          _fromData = JSON.stringify(emptyData);
        }
        this.formInfo = {
          Id: parseInt(result.id),
          SiteId: parseInt(result.siteId || 5),
          FolderId: parseInt(result.folderId),
          Title: title,
          Uid: this.$route.params["formId"],
          Data: _fromData,
          Status: status
        };

        //console.log('[getFormSchema] id', result.id);
        this.resizeFormHeight(result.id);
        // let _fromData = result.data || '';
        // if (_fromData.length < 100) {
        //   _fromData = JSON.stringify(emptyData);
        // }
        // this.formInfo = {
        //   Id: parseInt(result.id),
        //   SiteId: parseInt(result.siteId || 5),
        //   FolderId: parseInt(result.folderId),
        //   Uid: this.$route.params['formId'],
        //   Title: result.title,
        //   Data: _fromData
        // };

        this.formSchema = JSON.parse(_fromData).schema;

        if (status === 'Unpublished') {
          this.hideUnpublishedForm();
        }

      }).catch(e => {
        console.error('[get form schema] error:', e);
        this.siteInfo.ErrorMsg = `getting form schema error: ${e.message}`;
        this.formSchema = emptyData.schema;
      });


    },
    loadEmptyData() {
    },
    submitSuccess() {
      this.state.status = 1;
    },
    submitFailed(msg) {
      this.state.status = 2;
      if (msg) {
        this.siteInfo.ErrorMsg = msg;
      }
    },
    resetForm() {
      this.state.status = 0;
      this.state.submit = 0;
    },
    getEndpoint(formData$, form$) {

      if (this.state.submit > 0) {
        return;
      }
      this.state.submit = 1;


      let data = form$.requestData || {};

      let files = getUploadFiles(data, this.formInfo.Uid, this.formSchema);

      if (files && files.length > 0) {
        this.submitWithFiles(data, files);
      } else {
        this.submit(data);
      }
    },
    submit(data, files) {


      const res = formatFormData(data, files, this.formSchema);

      //console.log('[submit] res', res);
      let formData = {
        FormUid: this.formInfo.Uid,
        PageId: this.parentInfo.itmId,
        PageUrl: this.parentInfo.pageUrl,
        Data: JSON.stringify(res)
      };

      if (res.price && res.price > 0) {
        let priceInfo = {
          Items: []
        };
        priceInfo.Items.push({
          Title: this.formInfo.Title,
          Rate: res.price * 100,
          Quantity: 1
        });
        formData = { ...formData, Price: priceInfo };
      }

      //console.log('[submit] formData', formData);
      if (formData.PageId === 0) {
        console.error('[submit] no endpoint');
        this.submitFailed('no pageId');
      } else {

        axios.post(apiConfig.endpoint, formData).then(res => {
          if (res.status === 200) {

            if (res.data && res.data.returnUrl && res.data.returnUrl.length > 0) {

              window.top.location.href = res.data.returnUrl;
            } else {

              this.submitSuccess();
            }

          } else {
            this.submitFailed(`submit error 1: ${res.status}`);
          }

        }).catch(e => {
          console.error('[submit] error:', e);
          this.submitFailed(`submit error: ${e.message}`);

        });
      }
    },
    submitWithFiles(data, files) {
      uploadFiles(files).then((res) => {

        this.submit(data, files);
      }).catch(e => {
        console.error('[submitWithFiles] error:', e);
        this.siteInfo.ErrorMsg = `submit error: ${e.message}`;
        this.submitFailed(`submit error 2: ${e.message}`);

      });
    },
    checkIframe() {
      if ((window.frameElement && window.frameElement.tagName === 'IFRAME') || (window !== window.top)) {

        window.addEventListener("message", (e) => {
          if (e.data.type === 'Form.Branding') {
            if (!this.parentInfo.init) {
              console.info('[Form.Branding]', e.data.ci);
              let res = e.data.ci;
              this.parentInfo = {
                init: true,
                itmId: res.itmId || 0,
                pageUrl: res.pageUrl || '',
                siteId: res.siteId || 0,
                theme: res.theme || {},
                title: res.title || ''
              };
              if (res.theme && res.theme.pb) {
                this.theme.primary = res.theme.pb;
                this.theme.darker = res.theme.pd || res.theme.pb;
                this.btnStyle = `background-color:${res.theme.pb}!important;`;
              }
              if (res.isMobile) {
                this.parentInfo.isMobile = true;
                this.mainClass = 'ci-form-main-iframe ci-form-main-iframe-mobile';
              }

              if (res.pageUrl && res.pageUrl.indexOf('?result=ok') > 0) {
                this.state.status = 1;
              }
            }
          } else {
            console.info('[Form.Branding] e', e);

          }
        }, false);


        this.$nextTick(() => {
          if (!this.state.resizeObserve) {
            this.state.resizeObserve = new ResizeObserver((entires) => {

              this.resizeFormHeight();
            });
          }
          this.state.resizeObserve.observe(this.$refs.formContainer);
        });
      } else {
        this.mainClass = 'ci-form-main';
      }
    },
    resizeFormHeight(id) {

      let formHeight = this.$refs.formContainer?.offsetHeight;
      //console.log('[resizeFormHeight] formHeight', formHeight);
      if (formHeight && formHeight !== this.state.height || id) {
        let formId = id || this.formInfo.Id;
        this.state.height = formHeight;
        let cbMsg = {
          type: 'Form.Resize',
          id: parseInt(formId),
          val: formHeight
        };
        console.info('[postMessage] msg', cbMsg);
        if (cbMsg.id) {
          parent.postMessage(cbMsg, "*");
        }

      }
    },
    hideUnpublishedForm() {

      let formId = this.formInfo.Id;
      let cbMsg = {
        type: 'Form.Resize',
        id: formId,
        val: 0
      };
      console.info('[postMessage]', cbMsg);
      parent.postMessage(cbMsg, "*")
    }
  },
  created() {

  },
  async mounted() {
    this.checkIframe();
    this.getFormSchema();
  },
}
</script>
<style lang="scss">
.themed-form {
  padding: 0 5px;

  *,
  &:before,
  &:after,
  &:root {
    --vf-primary: v-bind(theme.primary);
    --vf-ring-color: v-bind(theme.darker);
  }
}

.submit-result {
  .btn-close {
    background-color: var(--vf-bg-btn);
    width: 120px;
  }

  div {
    font-size: 16px;
    line-height: 44px;
  }
}

.ci-form-main-iframe {
  padding: 10px 20px 100px 10px;
}

.ci-form-main-iframe-mobile {
  padding: 10px 0 30px 0 !important;
}
</style>