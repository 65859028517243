// builder.config.js

// You might place this anywhere else in your project
import '@vueform/builder/index.css';
import { defineConfig } from '@vueform/builder'

// builder.config.js

import { en_US } from '@vueform/builder';

en_US.simple_radiogroup_label = 'Price';
en_US.simple_radiogroup_description = 'Price control';
en_US.simple_radiogroup_field_label = 'Price';

en_US.radiogroup_label = 'Price';
en_US.radiogroup_description = 'Price control';
en_US.radiogroup_field_label = 'Price';
export default defineConfig({});