import { createRouter, createWebHistory, createWebHashHistory } from 'vue-router';
import Form from '../pages/Form.vue';
import Builder from '../pages/Builder.vue';
import Success from '../pages/Success.vue';
import Failed from '../pages/Failed.vue';
import Error from '../pages/Error.vue';
import View from '../pages/View.vue';
import Test from '../pages/Test.vue';

const router = createRouter({
    history: createWebHistory(),
    routes:[
        {
            path: '/',
            component: Error
        },
        {
            path: '/',
            component: Error
        },
        {
            path: '/form',
            component: Error
        },
        {
            path: '/',
            component: Error
        },
        {
            path: '/success',
            component: Success
        },
        {
            path: '/failed',
            component: Failed
        },
        {
            path: '/:formId',
            component: Form
        },
        {
            path: '/form/:formId/:email',
            component: Form
        },
        {
            path: '/form/:formId',
            component: Form
        },
        {
            path: '/view/:formId/:pageId/:itemId',
            component: View
        },
        {
            path: '/edit/:formId/:email',
            component: Builder
        },
        {
            path: '/edit/:formId',
            component: Builder
        },
        {
            path: '/test',
            component: Test
        },
        {
            path: '/:pathMatch(.*)*',
            redirect: '/'
        }
    ]
});

export default router;