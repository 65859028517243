import { useAuth } from "./useAuth";


export const msalPlugin = {
    install: (app, options) => {
        const { msalState, initAuth } = useAuth();
        if (!msalState.isAuthenticated) {
            initAuth();
        }

    }
}