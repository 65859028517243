import { createApp } from 'vue'
import './style.css'
import App from './App.vue'
import Vueform from '@vueform/vueform/plugin'
import vueformConfig from './../vueform.config'
import Builder from '@vueform/builder'
import builderConfig from './../builder.config'
import router from './router'
import { msalConfig } from './config/msalConfig'
import { msalPlugin } from './auth/msalPlugin'

const app = createApp(App);
app.use(Vueform, vueformConfig);
app.use(Builder, builderConfig);
app.use(msalPlugin, msalConfig);
app.use(router);
//app.mount('#app')
router.isReady().then(() => app.mount('#app'));