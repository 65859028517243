<template>
    <div class="h-screen wrapper">
      <div v-if="dialogInfo.show" class="ci-layer">
        <Dialog :info="dialogInfo" :handleOk="handleDialogOk" :handleCancel="handleDialogCancel"></Dialog>
      </div>
      <div v-if="alertDialogInfo.show" class="ci-layer">
        <Dialog :info="alertDialogInfo" :handleOk="handleAlertDialogOk" :handleCancel="handleAlertDialogCancel"></Dialog>
      </div>
      <Header :info="formInfo" :siteInfo="siteInfo" :submit="setFormData" :submitDraft="saveAsDraft"
        :deleteDraft="deleteDraft" :submitUnpublish="handleUnpublish" :submitPublish="publish" :login="handleLogin"
        :cancel="handleCancel" :changeMode="handleChangeMode" :state="msalState"></Header>
      <div v-if="siteInfo.ErrorMsg.length > 0" id="ci_error_msg" class="hwifMk">
        <div class="msg">{{ siteInfo.ErrorMsg }}</div>
        <div class="btn" @click="deleteError">X</div>
      </div>
      <main>
        <VueformBuilder ref="ciFormBuilder" @save="handleSave" />
      </main>
    </div>
  </template>
  <script>
  import Header from "../components/Header.vue";
  import Dialog from "../components/Dialog.vue";
  import axios from "axios";
  import { emptyData, messages } from "../data";
  import { useAuth } from "../auth/useAuth";
  import { apiConfig } from "../config/api";
  import { useConfig, isDevMode } from "../config/builderConfig";
  import { getFormStatus } from '../utilities/dataHelper';
  
  
  const { msalState, msalApp, postRequest, retryLogin } = useAuth();
  
  export default {
    name: "Builder",
    data() {
      return {
        //vfConfig: useConfig(this.$route.query),
        msalState: msalState,
        siteInfo: {
          IsDeveloperMode: isDevMode(this.$route.query),
          SgUrl: apiConfig.sgUrl,
          ErrorMsg: "",
          EnableSave: false,
        },
        formInfo: {
          Title: "My Form",
          Id: 0,
          //Id: parseInt(this.$route.params['formId'] || 0),
          Uid: this.$route.params["formId"],
          SiteId: 5,
          FolderId: 0,
          Data: "",
          Status: "Published",
          HasPub: false,
          HasDraft: false
        },
        dialogInfo: {
          show: false,
          header: "Save",
          loading: "processing...",
          success: "",
          failed: "",
          enabledOK: true,
          enabledCancel: false
        },
        alertDialogInfo: {
          show: false,
          header: "Alert",
          loading: "processing...",
          success: "",
          failed: "",
          enabledOK: true,
          enabledCancel: true
        },
        theme: {
          primary: '#0078d4',
          darker: '#0078d4'
        },
        pubData: ''
      };
    },
    components: {
      Header,
      Dialog,
    },
    methods: {
      deleteError() {
        this.siteInfo.ErrorMsg = "";
      },
      handleDialogOk() {
      },
      handleDialogCancel() {
      },
      handleAlertDialogOk() {  
      },
      handleAlertDialogCancel() {
        this.alertDialogInfo = {
          show: false,
          header: "Alert",
          loading: "",
          success: "",
          failed: "",
          enabledOK: true,
          enabledCancel: true
        };
      },
      handleChangeMode() {
        let path = `/edit/${this.formInfo.Uid}?v=${this.siteInfo.IsDeveloperMode ? 'simple' : 'advanced'}`;
        window.location.href = path;
      },
      handleCancel() {
        window.close();
      },
      handleLogin() {
        try {
          msalApp
            .loginPopup(postRequest)
            .then((res) => {
              this.msalState.isAuthenticated = true;
              this.msalState.token = res.accessToken;
              this.msalState.user = res.account;
  
              this.getFormData();
            })
            .catch((e) => {
              console.error("loginPopup error", e);
            });
        } catch (e) {
          console.error("loginPopup error", e);
        }
      },
      handleSave(builderObject, history) {
        console.log('[handleSave] builderObject', builderObject);
        let data = JSON.stringify(builderObject);
        this.formInfo.Data = data;
        this.siteInfo.EnableSave = true;
      },
      setFormData() {  
      },
      saveAsDraft() {  
      },
      publish() {  
      },
      handleUnpublish(){
      },
      unpublish() {
      },
      deleteDraft() {  
      },
      getFormData() {
      },
      loadEmptyData() {
        let builderObject = emptyData;
        let history = [];
        //this.$refs.ciFormBuilder.load(builderObject, history);
      },
    },
    async mounted() {
      this.loadEmptyData();
    },
  };
  </script>
  <style lang="scss">
  .vfb-builder {
  
    *,
    &:before,
    &:after,
    &:root {
      --vf-primary: v-bind(theme.primary);
      --vf-ring-color: v-bind(theme.darker);
    }
  }
  </style>