import { PublicClientApplication, LogLevel } from '@azure/msal-browser';
import { reactive } from 'vue';

const getClientInfo = () => {

    // try {
    //     console.log('[import.meta]', import.meta);
    //     console.log('[import.meta.env]', import.meta.env);
    // }
    // catch (e) {
    //     console.error(e);
    // }

    const key = window.location.host.split('.')[0].toLocaleLowerCase();
    switch (key) {
        case 'sbdemo':
            return {
                clientId: '813bb564-6eb2-4eda-bb64-92994f480e09',
                authority: 'https://login.microsoftonline.com/ac9bc8a3-592e-4cbe-a0e3-8941048741e2'
            };
        default:
            return {
                clientId: '17ff9d82-c5ce-4aa1-a56d-163188755dc0',
                authority: 'https://login.microsoftonline.com/549138e9-3c2a-458a-82e0-8a1c10e55a7b'
            };
    }
};

const auth = getClientInfo();

export const msalConfig = {
    auth: {
        ...auth,
        redirectUri: '/builder'
    },
    cache: {
        cacheLocation: 'sessionStorage',
        storeAuthStateInCookie: true
    },
    system: {
        allowNativeBroker: false, // Disables WAM Broker
        loggerOptions: {
            /**
             * Below you can configure MSAL.js logs. For more information, visit:
             * https://docs.microsoft.com/azure/active-directory/develop/msal-logging-js
             */
            loggerCallback: (level, message, containsPii) => {
                if (containsPii) {
                    return;
                }
                switch (level) {
                    case LogLevel.Error:
                        console.error(message);
                        return;
                    case LogLevel.Info:
                        console.info(message);
                        return;
                    case LogLevel.Verbose:
                        console.debug(message);
                        return;
                    case LogLevel.Warning:
                        console.warn(message);
                        return;
                    default:
                        return;
                }
            },
        },
    },
};
export const msalState = reactive({
    isAuthenticated: false,
    user: null,
    token: ''
});
export const loginRequest = {
    scopes: ["User.Read"]
};

export const postRequest = {
    scopes: [`api://${msalConfig.auth.clientId}/SiteGovern.Write`] // Add your required scopes
};

export const silentRequest = {
    loginHint: "Admin@sbqa01.ca"
};

export const msalApp = new PublicClientApplication(msalConfig);