//import AES from 'crypto-js/aes';
import { msalApp, msalConfig, msalState, loginRequest, silentRequest, postRequest } from '../config/msalConfig';
export const useAuth = () => {

    const initAuth = () => {
        msalApp.initialize().then(() => {
            msalApp.handleRedirectPromise().then(handleResponse).catch(err => {
                console.error("[useAuth] Error: " + err);
            });
        });

    };

    const handleResponse = (resp) => {
        const isInIframe = window.parent !== window;
        if (!isInIframe) {
            if (resp !== null) {
                accountId = resp.account.homeAccountId;
                getTokenRedirect(postRequest, resp.account);
            } else {

                let currentAccounts = msalApp.getAllAccounts();

                if (currentAccounts && currentAccounts.length > 0) {
                    loginSlient(currentAccounts[0]);
                } else {
                    const request = getSilentRequest();

                    console.log('[handleResponse] request', request);

                    msalApp.ssoSilent(request).then(() => {
                        currentAccounts = msalApp.getAllAccounts();

                        if (currentAccounts && currentAccounts.length > 0) {
                            loginSlient(currentAccounts[0]);
                        }
                    }).catch(error => {
                        console.error("[useAuth] Silent Error: " + error);
                        //signIn("loginPopup");
                    });
                }
            }
        }
    };

    const loginSlient = (account) => {
        console.log('[loginSlient] account', account);
        msalState.isAuthenticated = true;
        msalState.user = account;
        getTokenRedirect(postRequest, account).then(res => {
            msalState.token = res.accessToken;
        });
    };

    const getTokenRedirect = async (request, account) => {
        request.account = account;
        return await msalApp.acquireTokenSilent(request).catch(async (error) => {
            if (error instanceof msal.InteractionRequiredAuthError) {
                // fallback to interaction when silent call fails
                console.error("[useAuth] acquiring token using redirect");
                msalApp.acquireTokenRedirect(request);
            } else {
                console.error("[useAuth] getTokenRedirect Error: " + error);
            }
        });
    };

    const retryLogin = (email) => {
        const req = {
            loginHint: decode(email)
        }
        msalApp.ssoSilent(req).then(() => {
            const currentAccounts = msalApp.getAllAccounts();
            if (currentAccounts && currentAccounts.length > 0) {
                msalState.isAuthenticated = true;
                msalState.user = currentAccounts[0];
                const accountId = currentAccounts[0].homeAccountId;
                getTokenRedirect(postRequest, currentAccounts[0]).then(res => {
                    msalState.token = res.accessToken;
                });
            }
        }).catch(error => {
            console.error("[retryLogin] Silent Error: " + error);
            //signIn("loginPopup");
        });
    };

    const hint_key = 'msal_silent_request_hint';

    const getSilentRequest = () => {


        const search = (window.location.search || '').replace('?', '');
        if (search && search.length > 20) {

            let hint = decode(search);

            if (checkEmail(hint)) {
                localStorage.setItem(hint_key, hint);

                return {
                    loginHint: hint
                };

            }
        } else {
            let hint = localStorage.getItem(hint_key);
            if (hint) {
                return {
                    loginHint: hint
                };
            }
        }

        return silentRequest;
    }

    const decode = (code) => {

        try {
            return decodeURIComponent(atob(code));

        } catch (e) {
            console.error('[decode] error', e);
            return '';
        }
    };

    const checkEmail = (email) => {
        email = email || '';
        const emailRegex = /^[a-zA-Z0-9]+([-+._]*[a-zA-Z0-9]+)*@[a-zA-Z0-9]+([-+._]*[a-zA-Z0-9]+)*(\.[a-zA-Z0-9]+)$/;
        return emailRegex.test(email);
    }
    return { initAuth, msalState, postRequest, msalApp, retryLogin };
};